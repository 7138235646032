/* Typography */

@font-face {
  font-family: 'Barabara';
  src: url('./fonts/BARABARAFINALRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Franklin Gothic';
  src: url('./fonts/ITC Franklin Gothic LT Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Asap';
  src: url('./fonts/Asap-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Asap-Bold';
  src: url('./fonts/Asap-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  color: #1d519c;
  font-family: 'Asap', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

section {
  background-attachment: fixed;
}

h1 {
  line-height: 1.8;
}


/* Classes */

.logo {
  padding-top: 40px;
  width: 200px;
  transition: 0.5s;
 }

 .logo:hover {
   transform: scale(1.2);
 }

.download, a {
  color: white;
  transition: 0.4s;
}

.download:hover {
  opacity: 0.5;
  transform: translateY(-20px);
}

.header-logo {
  width: 130px; 
  margin-top: -20px;
  margin-left: 5px;
}

.social-logo {
  margin-left: 10px;
  transition: 0.4s;
}

.social-logo:hover {
  transform: scale(1.2);
}

.footer-logo {
  width: 130px;
  margin-top: -20px;
  margin-left: 5px;
}

.icon {
  color: red;
}

.-red {
  color: #ee424d;
  font-family: 'Asap-Bold', sans-serif;
  text-decoration: none;
}

.-blue {
  color: #1d519c;
  font-family: 'Asap-Bold', sans-serif;
  text-decoration: none;
}

.-link {
  text-decoration: none;
}

.-lesser {
  margin-top: -50px;
}
