/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  .scrollable::-webkit-scrollbar {
    display: none;
} 

.scrollable{
    display: flex;
    overflow-x: auto;
}